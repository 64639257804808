import React, { Component } from 'react';
import * as API from '../api/web';

import location_indicator from '../assets/location_indicator.png'



class ActivityTile extends Component {
  constructor(props){
    super(props)
    console.log(props)
  }

  state = {
    icon: '🍹',
    activity_title: "An activity",
    location_title: "Somewhere cool",
    uids: []
  }

  componentWillMount = async() => {

  }

  render() {

    return (
  <div 
  id={this.props.id || null}
  class={"tile-block "+this.props.extra_class} style={{
  maxWidth:180, textAlign:'center'}}>
    <div style={{display:'grid', gridTemplateColumns:'40px 1fr'}}>
      <div style={{fontSize:'32px', backgroundColor:'rgba(255,255,255,.1)'}} id="emoji">{this.props.tile && this.props.tile.emoji}</div>
      <h3 id="activity-title">{this.props.tile && this.props.tile.activity_title}</h3>
    </div>
    <h5 id="location-title">
    <img style={{width:10, verticalAlign:'middle', marginTop:-2, marginRight:4}} src={location_indicator}/> {this.props.tile && this.props.tile.location_title || this.props.tile && this.props.tile.area}</h5>
    <div class="bottom-tile-area">
      {this.props.showJoinCircles ? 
      <div class="join-circles" style={{
        display:'grid',
        width: 
        (this.props.tile.uids.length * 26 + this.props.tile.uids.length * 8) - ((this.props.tile.uids.length - 1 ) * (18)), 
        gridTemplateColumns:'1fr 1fr'}}>
        {this.props.tile.uids.map((uid, i) => <div 
        style={{marginLeft: i > 0 ? -10 : 0}}
        class="tile-avatar">
          <img src={this.props.userMap[uid].profile_image_url}/>
        </div>)}
      </div>
      :
      <div class="tile-btn">
        <div class="btn-bg"></div>
        I'm down!</div>
      }
    </div>
  </div>


    );
  }
}

export default ActivityTile;
