
import './App.css';

import Invite from './scenes/Invite';
import Index from './scenes/Index';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


function Download() {
  useEffect(() => window.location.replace("https://appsto.re/wagon"))
  return (<h1 id="redirect-header" style={{
    fontFamily:'Poppins', marginTop:60,
    fontSize:'18px', textAlign:'center'}}>Sending you on your way to great bars, restaurants and good times</h1>)
}
function App() {

  // Do the iOS or android check here?  
  return (
    <Router>
    <Switch>
    <Route path="/" exact component={Index} />
    <Route path="/dl" exact component={Download} />
    <Route path="/:join_or_platform_invite/:url_code" exact component={Invite}/>
    </Switch>
    </Router>
  );
}

export default App;


  
 
  
   
   

 
 
 
 
 
 
 
 
