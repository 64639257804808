import React, { Component } from 'react';
import * as API from '../api/web';

//import ActivityTile, * as Tile from '../components/ActivityTile';
import InviteTile from '../components/InviteTile';
import Flag from 'react-flagpack';
import countries from '../countries';
import weblogo from '../assets/web-logo.png';
import apple from '../assets/apple.png';
import instagram from '../assets/instagram.png';
import QRCode from "react-qr-code";
import Tilt from 'react-tilt';
import _ from 'lodash';
import jwt_decode from "jwt-decode";
import parsePhoneNumber from 'libphonenumber-js'


const storage = window.localStorage;

const checkForAccessToken = () => {
  var decoded = false
  var token = window.localStorage.getItem('access_token')
  console.log('access token here', token)
  if (token) {
    // If something goes wrong decoding it, remove it
    try {
      decoded = jwt_decode(token)
    } catch {
      window.localStorage.removeItem('access_token')
      return false; 
    }
    
    if (decoded && !decoded.expiration || 
      (decoded && decoded.expiration && decoded.expiration < new Date().valueOf()/1000 + (2 * 60))
      ) {
      window.localStorage.removeItem('access_token');
      return false 
    }
    return {decoded, token};
  } else {
    return false;
  }
}


class Invite extends Component {
  constructor(props){
    super(props)
    console.log(props)
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    name: null,
    step: 0, 
    phone_country: null,
    sender_name: 'A Wagon user',
    selected_country_dial_code: '+1',
    selected_country_code: 'US',
    preview_string:'IO',
    sms_token: '',
  }

  validateUserObject = () => {
    var name_pattern = /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/
    if (!name_pattern.test(this.state.name)) { return false }
    console.log('checking phone number')
    if (!parsePhoneNumber(this.state.selected_country_dial_code + this.state.phone_number)) { return false}
    return true; 
  }

  iOSorAndroid() {
    var iOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    var Android = navigator.userAgent.toLowerCase().indexOf("android") > -1; 
    console.log({iOS, Android})
    return {iOS, Android}
  }

  componentWillMount = async() => {
    var platform_or_activity = this.props.match.params.join_or_platform_invite
    // Set to true if we need to fetch a tile from somewhere for this view 
    var fetch_tile = false;
    // Set to true if we need to get the platform invite details 
    var fetch_platform_invite = false;
    // Join an activity
    if (platform_or_activity == 'join') {
      this.setState(
        { platform_or_activity,
          action_route: 'api/web-viewer/join-activity/'})
      fetch_tile = true; 
    } else if (platform_or_activity == 'invite') {
      this.setState({
        platform_or_activity,
        action_route: 'api/web-viewer/confirm-friend/'
      })
      fetch_platform_invite = true; 
    }

        
    // Check if access token exists 
    var token_check = checkForAccessToken();
    if (token_check && token_check.decoded && token_check.token) {
      var decoded = token_check.decoded
      console.log('decoded token here', decoded)
      // Write the info from the token to the state
      this.setState({
        access_token:token_check.token, 
        name: decoded.user.display_name, 
        uid: decoded.user._id,
        phone_number: decoded.user.phone_number,
        phone_country: decoded.user.phone_country})
    }

    console.log(countries[1])
    this.setState(this.iOSorAndroid())
    var url_code = this.props.match.params.url_code
    if (!url_code) {
      this.setState({error: "Not found"})
    } else {
      this.setState({url_code})
    }

    // Fetch a tile, currently used to display details for the activity 
    // invite on join links 
    if (fetch_tile) { 
      var results = await API.fetchInternal('api/web-viewer/activity/'+url_code)
      console.log('results here', results);
      // Needs results, a usermap, and a tile 
      if (!results || !results.userMap || !results.tile || !results.tile.confirmed) { return };
      // When a user joins this activity, let them text this set of 
      // phone numbers (direct link or QR code)
      var obj = _.pick(results.userMap, results.tile.confirmed)
      console.log('picked',obj)
      var arrayObj = Object.keys(obj).map(key => ({key, value: obj[key]}))
      console.log(arrayObj)
      var textable_phone_numbers = arrayObj.map(kv => kv.value.phone_country + kv.value.phone_number)
      console.log(textable_phone_numbers)
      this.setState({tile: results.tile, userMap: results.userMap, sender_name: results.userMap[results.sender_uid].display_name})
      this.setState({textable_phone_numbers})
    }


    if (fetch_platform_invite) {
      var results = await API.fetchInternal('api/web-viewer/invite/'+url_code)
      console.log('results here, getting a platform invite', results);
      // Needs results, a usermap, and a tile 
      if (!results || !results.userMap) { return };
      var tile =  {
        emoji: "🏖️",
        activity_title: "Hanging out", 
        location_title: results.userMap[results.sender_uid].areas[0],
        uids: [results.sender_uid], confirmed: [results.sender_uid]
      }
      this.setState({
        tile,
        userMap: results.userMap, 
        sender_name: results.userMap[results.sender_uid].display_name})
    }

  }

  // Requests an SMS code or performs the join-activity action when an access token is found
  auth = async () => {
    console.log(this.state.selected_country_dial_code, this.state.phone_number, this.state.name)
    if (!this.validateUserObject()) { return false }
    console.log('passed validation')
    let token_check = checkForAccessToken();
    // If this user has a valid access_token, just instantly confirm this action 
    // Also check if the phone number is the same, since that's what we use to auth this specific user 
    // if they have changed it, then they need to request a new auth token. 
    if (token_check && token_check.decoded && token_check.token && this.state.phone_number == token_check.decoded.phone_number) {
      var results = await API.postInternal(this.state.action_route+this.state.url_code+'/'+updated, {uid:this.state.uid, access_token: token_check.token})
      console.log(results);
    } else {
      var results = await API.postInternal('api/web-viewer/request-auth/', {
        phone_number: this.state.phone_number,
        phone_country: this.state.phone_country,
        name: this.state.name
      })
      console.log(results)
      // If a UID is returned, continue to auth code verification step 
      if (results.uid) {
        this.setState({uid: results.uid, step: 1})
      }
    }
    


  }



  handleAuthCodeInput = async (value) => {
    var mapping = {'1': this.first, '2': this.second, '3': this.third, '4': this.fourth}
    var current = this.state.sms_token;
    console.log({current})
    // If the current input field is empty, remove the last char from the token input
    // and then go back to the previous auth code input field 
    if (!value) {
      var updated = current.substring(0, current.length - 1)
      this.setState({sms_token: updated})
      mapping[updated.length+1].focus()
      console.log('no value, should remove last')

    // If there is a value, make an updated string with the new auth code
    } else {
      var updated = String(current) + String(value);
      // If it's larger than 4, don't update the state
      if (updated.length + 1 > 4) {
        // If the user enters 4 characters, fire the auth route 
        this.setState({loading_auth: true})
        var results = await API.postInternal(this.state.action_route+this.state.url_code+'/'+updated, {uid:this.state.uid, access_token: storage.getItem('access_token')})
        this.setState({loading_auth: false})
        // Handle error, just display the message from the backend
        if (results.error) {
          alert(results.error)
        }

        // Assign all these results to the state
        var current_state = this.state
        var updated_state = Object.assign(current_state, results)

        // Write all this shit to local storage so we can prefill it later 
        // and use it for auth as well
        window.localStorage.setItem('access_token', String(results.access_token))
        console.log(window.localStorage.getItem('access_token'))
        updated_state.step = 2;
        this.setState(updated_state);
        // Just always focus on the last one when it's maxed
        var next_field = mapping['4']
        next_field.focus()
        return
      
      // If it's not larger than 4, update with the new value, and go to the next field
      } else {
        console.log({updated})
        this.setState({sms_token: updated})
        console.log('jumping to this one: ', updated.length+1)
        var next_field = mapping[String(updated.length + 1)]
        next_field.focus()
      }
    }
  }
  // This function is fired when the user enters their SMS code 
  joinActivity = async () => {

    // No more validation needed since we just use the UID we stored in the state 
    var results = await API.postInternal('api/web-viewer/join-activity/'+this.state.url_code+'/'+this.state.sms_token, {
      uid: this.state.uid,
    })
    console.log('error')
    if (results.error) {this.setState({error:'sms_code'}); return}
    console.log(results)
    this.setState({step:2})
  }


  handleChange(e) {
    console.log(e.target.value);
    var selected_dial = countries.filter(c => c.code == e.target.value)[0].dial_code;
    this.setState({ selected_country_code: e.target.value, selected_country_dial_code: selected_dial });
  }




  render() {
    console.log(this.state.userMap)
    return (
    <div style={{marginBottom:100}}>
    <p class="hidden-text">Text 412 522 4260 for a free drink</p>
    <div id="header-bar">
      
      <div id="header-bar-inner">
        <div>
        <img id="web-logo-top" src={weblogo}/>
        </div>
        <div class="header-icon-grid">
          <div class="app-link">Get the app</div>
          <div class="header-icon"><img src={apple}/></div>
        </div>
      </div>
    </div>
  <div id="activity-container">
    <h2 id="invite-header" style={{marginBottom:14}}>
      {this.state.platform_or_activity == 'join' && 
      <> 
       {this.state.sender_name} has invited you to hang out
      </>}
      
      {this.state.platform_or_activity == 'invite' && 
      <>
      {this.state.sender_name} has invited you to hang out together on Wagon
      </>}
      </h2>

    <div class="top-margin-mobile-20">
    {this.state.tile && 
    <Tilt className="Tilt" options={{ max : 15, easing:"cubic-bezier(.03,.98,.52,.99)", reverse:true}} style={{ height: 250}} >

    <InviteTile
    extra_class={'shadow-hover'}
    userMap={this.state.userMap}
    showJoinCircles={true}
    tile={this.state.tile}/>
    </Tilt>}
    </div>
  <div>


    
    {this.state.step == 0 && 
    <>
          {this.state.platform_or_activity == 'join' && 
      <p class="prompt-p" style={{marginTop:24,
      color:'white', marginBottom:10, fontWeight:'600'}}>Enter your info to join</p>}
    <div class="input-container" style={{marginTop:16}}>
      <input 
      value={this.state.name}
      onChange={(e) => this.setState({name: e.target.value})}
      type="text" placeholder="Enter your first name"/>
      <div class="input-divider"></div>
      <div class="phone-country-container">
      <div class="country-thing">
              <div class="flag-container-phone-input">
              <Flag
                  code={this.state.selected_country_code}
                  size="m"
                  hasDropShadow
                  />
              </div>

              <select 
              onChange={this.handleChange}
              class="phone-country" 
              onBlur={() => this.setState({preview_string: "Noob"})}
              value={this.state.selected_country_code}>
              {countries.map(country => <option value={country.code}>
                {country.dial_code + " " + country.name}
                </option>)}
              </select>
              <span class="selected-country-dial-code">
              {this.state.selected_country_dial_code}
              </span>
      </div>
        <input 
        inputmode="numeric"
        onChange={(e) => this.setState({phone_number: e.target.value.replace(/\D/g,'')})}
        value={this.state.phone_number} class="phone-number-input" placeholder="(111) - 222 0000"/>
      </div>
    </div>


    <div id="button-terms-container" style={{display:'flex',
    flexDirection:'row', maxWidth:500}}>
    <div style={{marginTop:16}}>
    <div 
    onClick={() => this.auth()}
    class="default-btn">I'm down!</div>
    </div>
    <div id="terms-container" style={{marginTop:10, marginLeft:16}}>
    <p class="terms-p">
      By joining, you agree to our <br/> <a href="#">terms and privacy policy</a></p>
    </div>
    </div>
    </>
    }


  { // SMS VERIFICATION STEP -----> MAKE THESE THINGS COMPONENTS BRUH
  this.state.step == 1 && 
    <>
    <p class="prompt-p top-margin-mobile-20" style={{marginTop:24, marginBottom:20}}>We sent you a verification code by text</p>
      <div id="verification-code-container">
        <input inputmode="numeric" value={this.state.sms_token.split('')[0]} ref={(input) => { this.first = input; }} onChange={(e) => this.handleAuthCodeInput(e.target.value)}></input>
        <input inputmode="numeric" value={this.state.sms_token.split('')[1]} ref={(input) => { this.second = input; }} onChange={(e) => this.handleAuthCodeInput(e.target.value)}></input>
        <input inputmode="numeric" value={this.state.sms_token.split('')[2]} ref={(input) => { this.third = input; }} onChange={(e) => this.handleAuthCodeInput(e.target.value)}></input>
        <input inputmode="numeric" value={this.state.sms_token.split('')[3]} ref={(input) => { this.fourth = input; }} onChange={(e) => this.handleAuthCodeInput(e.target.value)}></input>
      </div>

      <div id="button-terms-container" style={{display:'flex',
      flexDirection:'row', maxWidth:500}}>
      <div style={{marginTop:16}}>
      <div 
      onClick={() => this.auth()}
      class="default-btn">I'm down!</div>
      </div>
      <div id="terms-container" style={{marginTop:10, marginLeft:16}}>
      <p class="terms-p">By joining the activity you agree to our <br/> <a href="#">terms of service and privacy policy</a></p>
      </div>
      </div>
    </>
    }

    
    {
    (this.state.step == 2) && this.state.tile && this.state.tile.confirmed && this.state.userMap && 
    <div id="qr-grid">
    <QRCode style={{width:40}} size={108} value={"sms:1-408-555-1212/1-408-555-2121/1-408-555-1221"}/>
    <div style={{marginTop:8}}>
      <p class="prompt-p top-margin-mobile-20" style={{marginTop:24, marginBottom:14, fontWeight:'500'}}>
      {this.state.platform_or_activity == 'join' &&  <>  Scan the QR code to text <br/> {this.state.sender_name} </>}
    {this.state.platform_or_activity == 'invite' &&  <>   Scan the QR code to open the Appstore </> }
     </p>
      <div style={{marginTop:4}}>
        <div 
        onClick={() => this.joinActivity()}
      class="default-btn">{this.state.platform_or_activity == 'join' ? 'Text me a link instead' : 'Open in Appstore'}
        </div>
      </div>
    </div>
    </div>
    }
    
  </div>
  </div>
  </div>


    );
  }
}

export default Invite;
