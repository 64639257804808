import React, { Component } from 'react';
import * as API from '../api/web';

import location_indicator from '../assets/location_indicator.png'

const DEFAULT_COLORS = ["#EFE", "#66AABB"]

const getColor = (name) => {
  let characterInt = name.charCodeAt(0) - 97
  switch (characterInt){
    case characterInt < 8: 
      return "#FF0000"
    case characterInt < 16:
      return "#00FF00"
    case characterInt < 24:
      return "#0000FF"
    default:
      return "#888888"
  }
}
class InviteTile extends Component {
  constructor(props){
    super(props)
    console.log(props)
  }

  state = {
    icon: '🍹',
    activity_title: "An activity",
    location_title: "Somewhere cool",
    uids: []
  }

  componentWillMount = async() => {

  }

  formatAttendingString() {
    console.log('formatting attending string')
    if (!this.props.userMap) {
      console.log('nothing here')
      return
    }
    console.log('09 Check this usermap', this.props.userMap)
    let str = ''
    let leftovers = [];
    // This string will only be displayed if there actually are other people in the activity 
    // So no need to do a check for that here 
    let filtered_uids = this.props.tile.uids
    console.log({filtered_uids})
    let filtered_names = filtered_uids.map(uid => this.props.userMap[uid].display_name) 
    if (filtered_names.length == 1) {
      // If there's only one other person attending, just render the full string 
      str = `with ${filtered_names[0]}`
      leftovers = filtered_names.slice(1, filtered_names.length);
      if (leftovers.length > 0) {
        // Add the second name, then conditionally render others if there is more than 1 other person in there
        str += `+${leftovers.length} ${leftovers.length > 1 ? 'others' : ""}`
      }
    } 
    return str
  }

  makeComponents() {
    let components = []
    // Handle multi objects 
    if (this.props.tile && this.props.tile.multi_object) {
      let obj = this.props.tile.multi_object
      let keys = Object.keys(obj)
      let i = 0;
      for (let key of keys) {

        let tile = obj[key]
        components.push(
          <div style={{
            paddingTop:i > 0 ? 12 : null,
            marginBottom: tile && tile.location_title ? 0 : 12,
            borderTop:i > 0 ? '1px solid rgba(255,255,255,.04)' : null}}>
          <div class="invite-tile-top">
          <div style={{fontSize:'32px'}} id="invite-emoji">{tile && tile.emoji}</div>
          <h3 id="invite-activity-title">{tile && tile.activity_title}</h3>
          </div>
          {tile && tile.location_title &&
          <div class="invite-location-block">
          <div style={{textAlign:'center'}}>
          <img style={{width:14, verticalAlign:'middle', display:'inline-block', marginTop:-4}} src={location_indicator}/>
          </div>
          <h5 id="invite-location-title">
          {tile && tile.location_title || tile && tile.area}
          </h5>
          </div>
          }
          </div>
        )

        i++;
      }
    } else {
      components.push(
        <>
        <div class="invite-tile-top">
        <div style={{fontSize:'32px'}} id="invite-emoji">{this.props.tile && this.props.tile.emoji}</div>
        <h3 id="invite-activity-title">{this.props.tile && this.props.tile.activity_title}</h3>
        </div>

        <div class="invite-location-block">
        <div style={{textAlign:'center'}}>
        <img style={{width:16, verticalAlign:'middle', display:'inline-block', marginTop:-4}} src={location_indicator}/>
        </div>
        <h5 id="invite-location-title">
        {this.props.tile && this.props.tile.location_title || this.props.tile && this.props.tile.area}
        </h5>
        </div>
        </>
      )
    }

    return (
      <div 
      id={this.props.id || null}
      class={"invite-tile-block "+this.props.extra_class} style={{
      maxWidth:'120%', textAlign:'center'}}>

      {
      // We made the components, just drop it in the middle here
      components
      }

      <div class="invite-bottom-tile-area"
      style={{gridTemplateColumns: String(this.props.tile.uids.length * 26 + this.props.tile.uids.length * 8) - ((this.props.tile.uids.length - 1 ) * (18))+'px' +' 1fr'}}
      >
        <div class="invite-join-circles" style={{

          display:'grid',
          width: 
          (this.props.tile.uids.length * 26 + this.props.tile.uids.length * 8) - ((this.props.tile.uids.length - 1 ) * (18)), 
          gridTemplateColumns:'1fr 1fr'}}>
          {this.props.tile.uids.map((uid, i) => <div 
          style={{marginLeft: i > 0 ? -10 : 0}}
          class="tile-avatar">
            {this.props.userMap[uid].profile_image_url ? 
            <img src={this.props.userMap[uid].profile_image_url}/> :
            <div style={{color:'white', lineHeight:'26px',
            backgroundColor: getColor(this.props.userMap[uid].display_name.slice(0,1)),
            fontSize:'12px', fontWeight:'700'}}>{this.props.userMap[uid].display_name.slice(0,1)}</div>}
            
          </div>)}
        </div>
        <div style={{marginLeft:30}}>
        <p style={{
          textAlign:'left',
          fontSize:'13px', fontWeight:'500',
          padding:0, margin:0, lineHeight:'30px',
          display:'block', paddingRight:40}}>{this.formatAttendingString()}</p>
          </div>
      </div>
      </div>
    )
  }
  render() {
    if (this.props.userMap) {
      return (

        this.makeComponents()
      );
    } else {
      return (
        <p>Loading...</p>
      )
    }

  }
}

export default InviteTile;
