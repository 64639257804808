
const countries = [
    { "name": "United States", "dial_code": "+1", "code": "US" },
    { "name": "Netherlands", "dial_code": "+31", "code": "NL" },
    { "name": "Germany", "dial_code": "+49", "code": "DE" },
    { "name": "France", "dial_code": "+33", "code": "FR" }, 
    { "name": "Austria", "dial_code": "+43", "code": "AT" },
    { "name": "Belgium", "dial_code": "+32", "code": "BE" },
    { "name": "Brazil", "dial_code": "+55", "code": "BR" },
    { "name": "Canada", "dial_code": "+1", "code": "CA" },
    { "name": "Denmark", "dial_code": "+45", "code": "DK" },
    { "name": "Finland", "dial_code": "+358", "code": "FI" }, 
    { "name": "Greece", "dial_code": "+30", "code": "GR" },
    { "name": "Hong Kong", "dial_code": "+852", "code": "HK" },
    { "name": "Iceland", "dial_code": "+354", "code": "IS" },
    { "name": "Israel", "dial_code": "+972", "code": "IL" }, 
    { "name": "Italy", "dial_code": "+39", "code": "IT" }, 
    { "name": "Japan", "dial_code": "+81", "code": "JP" }, 
    { "name": "South Korea", "dial_code": "+82", "code": "KR" },
    { "name": "Luxembourg", "dial_code": "+352", "code": "LU" },
    { "name": "Mexico", "dial_code": "+52", "code": "MX" },
    { "name": "New Zealand", "dial_code": "+64", "code": "NZ" },
    { "name": "Norway", "dial_code": "+47", "code": "NO" },
    { "name": "Portugal", "dial_code": "+351", "code": "PT" },
    { "name": "Singapore", "dial_code": "+65", "code": "SG" },
    { "name": "Switzerland", "dial_code": "+41", "code": "CH" },
        ]
export default countries;