

import {BASE_PATH, DEV_PATH} from './config.js'

if (process.env.NODE_ENV == 'development') {
   var PATH = DEV_PATH
} else if (process.env.NODE_ENV != 'development'){
   var PATH = BASE_PATH
}

export async function fetchInternal(route){
    var constructed_route = `${PATH}/${route}`
    console.log(constructed_route)
    return fetch(constructed_route, {
    method: 'GET'})
    .then(
        async function(response) {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            console.log(response)
            return false;
        }

        let parsed = await response.json()
        return parsed
        }

    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
        return false 
    });
}

export async function postInternal(route, object){
    var composed_path = `${PATH}/${route}`
    console.log(composed_path)
    return fetch(composed_path, {
    method: 'POST', 
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(object)})
    .then(
        async function(response) {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            return false;
        }

        let parsed = await response.json()
        return parsed
        }

    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
        return false 
    });
}

export async function fetchExternal(route){
    console.log(route)
    return fetch(`${route}`, {
    method: 'GET'})
    .then(
        async function(response) {
        if (response.status !== 200) {
            console.log(response)
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            return false;
        }

        let parsed = await response.json()
        return parsed
        }

    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
        return false 
    });
}



export async function create(route, object){
    return fetch(`${PATH}/${route}`, {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(object)})
    .then(
        async function(response) {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            console.log(response)
            return false;
        }

        let parsed = await response.json()
        console.log({parsed})
        return parsed
        }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
        return false 
    });
}


export async function paymentIntent(host_id, duration){
    return fetch(`${PATH}/charges/payment-intent/${host_id}/${String(duration)}`, {
    method: 'GET'})
    .then(
        async function(response) {
        if (response.status !== 200) {
            return false;
        }

        let parsed = await response.json()
        return parsed
        }

    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
        return false 
    });
}