import React, { Component } from 'react';
import * as API from '../api/web';
import ActivityTile, * as Tile from '../components/ActivityTile';
import main from '../assets/main.png'
import weblogo from '../assets/web-logo-2.png'
import apple from '../assets/apple.svg'
import dlqr from '../assets/dl-qr.svg'
import rotation from '../assets/rotation.svg'
import sent from '../assets/check.png'
import instagram from '../assets/instagram.svg'
import Reward from 'react-rewards';
import QRCode from "react-qr-code";
import Flag from 'react-flagpack';
import countries from '../countries';
import parsePhoneNumber from 'libphonenumber-js';



class Index extends Component {
  constructor(props){
    super(props)
    console.log(props)

    this.state = {

      phone_country: null,
      selected_country_dial_code: '+1',
      selected_country_code: 'US',
      preview_string:'IO',
    }
  }

  validateUserObject = () => {
    if (!parsePhoneNumber(this.state.selected_country_dial_code + this.state.phone_number)) { return false}
    return true; 
  }

  handleChange(e) {
    var selected_dial = countries.filter(c => c.code == e.target.value)[0].dial_code;
    this.setState({ selected_country_code: e.target.value, selected_country_dial_code: selected_dial });
  }

  requestSMS = async () => {
    console.log(this.state.selected_country_dial_code, this.state.phone_number, this.state.name)
    if (!this.validateUserObject()) { return false }
    console.log('passed validation')
    this.setState({submitted:true})
    var results = await API.postInternal('api/web-viewer/request-download-link/', {
        phone_number: this.state.phone_number,
        phone_country: this.state.phone_country,
    });
  }

  render() {

    return (
      <>
      <div style={{position:'absolute', width:'100%', 
      pointerEvents:'none',
      height:'100%', overflow:'hidden'}}>
      <img  id="rotation" src={rotation} style={{width:'80%', left:100, top:-160, position:'relative'}}/>
      </div>
      <div style={{height:60}}></div>
      <div style={{maxWidth:400, margin:'0 auto', paddingTop:60, padding:10}}>
        <div style={{maxWidth:400, margin:'0 auto'}}>
        <div>
          <img src={weblogo} style={{width:140}}/>
        </div>
        <p class="main-header">
          See <span class="text-gradient">what's going on</span><br/>
          hang out with friends 
        </p>
        </div>

        <div style={{width:'100%', flexDirection:'column', display:'flex', marginTop:70}}>
        
        <div style={{display:'inline-flex', 
        flexDirection:'row', alignItems:'center', marginTop:-38}}>
          <Reward
            ref={(ref) => { this.reward = ref }}
            type='emoji'
            config={{
              elementCount:15,
              lifetime:50,
              decay:0.94,
              startVelocity:14,
              spread:100, angle:90,
              emoji:['🍸', '🕺','💃','🍹','🍦']}}
          >

          <a href="https://appstore.com">
          <div 
          onMouseEnter={() => this.reward.rewardMe()}
          id="app-download-button">
            <img style={{width:16, verticalAlign:'middle'}} src={apple}/>
            <p>Get the app</p>
          </div>
          </a>
          </Reward>

          <a href="https://instagram.com/wagon.social">
          <div id="insta-button">
          <img src={instagram}/>
            <p>Insta</p>
          </div>
          </a>
        </div>
        </div>

        </div>

        <div style={{paddingLeft:20, paddingRight:20}}>
        <div 
        id="lc-block"
        style={{maxWidth:'100%',
        marginTop:24,
         border:'1px solid #38394A',
         }}>
          <div 
          id="launch-cities-header"
          style={{maxWidth:400,
          margin:'0 auto',
            display:'grid', gridTemplateColumns:'160px 160px'}}>
          
          <div id="launch-cities" class="drop-borders">
            <h4><a>Launched</a> </h4>
            <h3>New York</h3>
            </div>
            <div id="launch-cities">
            <h4>Launching soon</h4>
            <h3>Miami & LA</h3>
          </div>
          </div>

          <div
          style={{
            borderBottomLeftRadius:6, borderBottomRightRadius:6,
            backgroundColor:'#38394A', fontWeight:'500', paddingLeft:8, paddingTop:8, paddingBottom:8, fontSize:'11px'}}
          >Text us at <a>412 522 4260</a> if you want to help us expand</div>
        </div>
        </div>



        <div id="download-request-container">
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}> 
          {!this.state.submitted ? 
          <div id="qr-hover" class="hide-on-mobile">
            <div style={{margin:'4px 4px'}} id="qr">
            <img src={dlqr} style={{width:16}}/>
            </div>
          </div>
          :
          <div id="confirm-check">
            <img src={sent} style={{width:24}}/>
          </div>
          }
          <h3 id="request-download" style={{marginLeft:12}}>{this.state.submitted ? "We've texted you the link" : 'Get the App Store link via text'}</h3>
        </div>
        
        {!this.state.submitted &&
        <div class="input-container" style={{}}>
      
      
      <div class="phone-country-container">
      
      <div class="country-thing">
              <div class="flag-container-phone-input">
              <Flag
                  code={this.state.selected_country_code}
                  size="m"
                  hasDropShadow
                  />
              </div>

              <select 
              onChange={(e) => this.handleChange(e)}
              class="phone-country" 
              onBlur={() => this.setState({preview_string: "Noob"})}
              value={this.state.selected_country_code}>
              {countries.map(country => <option value={country.code}>
                {country.dial_code + " " + country.name}
                </option>)}
              </select>
              <span class="selected-country-dial-code">
              {this.state.selected_country_dial_code}
              </span>
      </div>
        
        <input 
        inputmode="numeric"
        onChange={(e) => this.setState({phone_number: e.target.value.replace(/\D/g,'')})}
        value={this.state.phone_number} class="phone-number-input" placeholder="(111) - 222 0000"/>
      </div>

    </div>
    }
 
    {!this.state.submitted && 
    <div id="button-terms-container" style={{display:'flex',
    flexDirection:'row', maxWidth:500}}>
    <div style={{marginTop:16}}>
    <div 
    onClick={() => this.requestSMS()}
    class="default-btn">Text me!</div>
    </div>
    <div id="terms-container" style={{marginTop:10, marginLeft:16}}>
    <p class="terms-p">
      <a href="#">Terms and privacy policy</a> <br/>Normal messaging rates may apply.</p>
    </div>
    </div>
    }
    </div>

     </>


    );
  }
}

export default Index;
